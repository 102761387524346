import { message } from 'antd'
import { hideModal, showModal } from '@managers/GlobalModal'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import pathUrl from '@routes/path'
import { CheckCircleFilled } from '@ant-design/icons'

import { authApis } from '../auth.apis'

const EmailVerification: React.FC = () => {
  const location = useLocation()
  const history = useHistory()

  const searchParams = new URLSearchParams(location?.search)
  const token = searchParams.get('token')

  useEffect(() => {
    const handleVerifyAccount = async () => {
      if (!token) {
        return
      }
      try {
        const response = await authApis.verifyAccount(token)

        if (response?.message?.toUpperCase() === 'VERIFIED_SUCCESSFULLY') {
          showModal({
            content: (
              <div className=''>
                <div className='text-center mb-4'>
                  <CheckCircleFilled style={{ color: '#2B8700', fontSize: 54 }} />
                </div>
                <div className='text-[#2B8700] font-medium text-center mb-4'>Đăng ký tài khoản thành công!</div>
              </div>
            ),
            onCancel: () => {
              hideModal()
              history.push(pathUrl.home())
            },
          })

          return
        }

        history.push(pathUrl.home())
      } catch (error: any) {
        message.error(error?.message ?? 'Error')
        history.push(pathUrl.home())
      }
    }

    handleVerifyAccount()
  }, [])

  return <div />
}

export default EmailVerification
