/* eslint-disable no-console */
/* eslint-disable react/no-danger */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useState } from 'react'
import { Modal, Row } from 'antd'
import { OnProgressProps } from 'react-player/base'
import { ReactPlayerProps } from 'react-player'
import _ReactPlayer from 'react-player/youtube'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Trans, useTranslation } from 'react-i18next'
import { isNumber, throttle, isEmpty } from 'lodash'

import {
  show5MinutesModal,
  showEndTimeOutModal,
  showPendingUpgradeModal,
  showTimeOutModal,
} from '@containers/RemainingMinutes/RemainingMinutesManager'
import { useParams } from 'react-router-dom'

import Logger from '@managers/Logger'

import { useDemoAccount } from '@managers/DemoAccount/useDemoAccount'
import { hideModal, showModal } from '@managers/GlobalModal'
import { useVideoDemoAccount } from '@managers/DemoAccount/useVideoDemoAccount'
import TrialPeriodDemoAccountExpired from '@managers/DemoAccount/components/TrialPeriodDemoAccountExpired'

import { ICustomer, ResultRecordRequestType, VideoType, UserType } from '../../../../types'

import playImg from '../../../../assets/imgs/play-btn.png'
import pauseImg from '../../../../assets/imgs/pause-btn.png'
import './styles.scss'
import pointRuleSelector from '../../../../recoil/pointRuleSelector'
import resultRecordAtom, { sumPointSelector } from '../../../../recoil/resultRecordAtom'
import useModal from '../../../../components/useHook/useModal'
import ReviewPointModal from '../../../../components/Modal/ReviewPointModal'
import { formatPoint, generateDetailVideo, mobileAndTabletCheck } from '../../../../utils'
import { EResultStatus, IResultRecord, apis } from '../../../../apis'
import detailVideoAtom from '../../../../recoil/detailVideoAtom'
import ThanksUserCodeModal from '../../../../components/Modal/ThanksUserCodeModal'
import { ACCEPTED_TOLERANCE, DEMO_ACCOUNT } from '../../../../constants/constants'
import ReceivePrizeBtn from '../../../../components/Button/ReceivePrizeBtn'
import ShareBtn from '../../../../components/Button/ShareBtn'
import userInfoAtom, { concentrationLevelSelector } from '../../../../recoil/userInfoAtom'
import QuizzesModal from '../../../../components/Modal/QuizzesModal'
import appModalAtom from '../../../../recoil/appModalAtom'
import { ModalType } from '../../../../types/enum'
import imagesAssets from '../../../../assets/imgs'
import BoxUsedPoint from './BoxUsedPoint'
import useInfoProfile from '../../../../components/useHook/useInfoProfile'

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>

let checkedAnswers: Record<number, boolean> = {} // store answer time that recorded to avoid count many times

const getVideoIdLocalStorage = (videoId?: string) => {
  return `played_${videoId}`
}

interface IVideoPayload {
  time: string
  activeQuestionIndex?: number
  quizIndex?: number
  resultRecord: ResultRecordRequestType
}

const setVideoInfoLocalStorage = (videoId = '', payload: IVideoPayload) => {
  localStorage.setItem(getVideoIdLocalStorage(videoId), JSON.stringify(payload))
}

const removeVideoInfoLocalStorage = (videoId = '') => {
  localStorage.removeItem(getVideoIdLocalStorage(videoId))
}

const getVideoInfoLocalStorage = (videoId = ''): IVideoPayload | undefined => {
  const data = localStorage.getItem(getVideoIdLocalStorage(videoId))

  if (data) {
    return JSON.parse(data)
  }

  return undefined
}

const Video: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { classID } = useParams<{ videoID: string; classID?: string }>()

  const { handleRefreshProfile } = useInfoProfile()
  const { isDemoAccount } = useDemoAccount()
  const { isAvailableUpdate, updateMinutesUsed } = useVideoDemoAccount()

  const [playing, setPlaying] = React.useState(false)
  const pointMessageRef = React.useRef()
  const reactPlayerRef = React.useRef<any>()
  const reactLayerPlayerRef = React.useRef<any>()
  const quizIndexRef = React.useRef<number>(0)

  const pointRule = useRecoilValue(pointRuleSelector)
  const [detailVideo, setDetailVideo] = useRecoilState(detailVideoAtom)
  const setAppModal = useSetRecoilState(appModalAtom)
  const [resultRecord, setResultRecord] = useRecoilState(resultRecordAtom)
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom)
  const [resultPreviousRecord, setResultPreviousRecord] = React.useState<IResultRecord>()
  const [exchangePoints, setExchangePoints] = React.useState(!!userInfo?.email)
  const concentrationLevel = useRecoilValue(concentrationLevelSelector)
  const [showModalContinue, setShowModalContinue] = useState(false)

  const currentLimitTimeRef = React.useRef<number>(userInfo?.minutes_limit ?? 0)
  const currenAvailableTimeRef = React.useRef<number>(userInfo?.available_minutes ?? 0)
  const stateTimeAgoRef = React.useRef<OnProgressProps>()
  const totalTimeWatchVideo = React.useRef<number>(0)

  const codeLanguage = i18n.language ?? 'vi'

  const isCantPlay =
    isNumber(userInfo.minutes_limit) &&
    isNumber(userInfo.available_minutes) &&
    userInfo.minutes_limit <= 0 &&
    userInfo.available_minutes <= 0

  useEffect(() => {
    if (userInfo?.email) {
      setExchangePoints(true)
    }
  }, [userInfo?.email])

  const {
    id: videoId,
    video,
    name,
    questions,
    activeQuestionIndex,
    quizzes,
    is_sponsor,
    keywords = [],
    customer = {} as ICustomer,
  } = detailVideo || {}
  const question = questions?.[activeQuestionIndex!]

  const textPoint = is_sponsor ? t('video.vnd') : t('video.score')

  const isVideoFree = !detailVideo?.is_sponsor && detailVideo?.price === 0

  const istTimeOutVideo =
    isVideoFree &&
    typeof userInfo?.minutes_limit !== 'undefined' &&
    typeof userInfo?.available_minutes !== 'undefined' &&
    userInfo?.minutes_limit <= 0 &&
    userInfo?.available_minutes <= 0 &&
    userInfo?.customer?.minutes_limit !== 0

  const resetWatchingSession = React.useCallback(
    (forceRandomQuestionIndex?: boolean) => {
      if (forceRandomQuestionIndex) {
        setDetailVideo((detailVideo) => (detailVideo ? generateDetailVideo(detailVideo) : detailVideo))
      }
      setPlaying(false)
      setResultRecord({ result: [], quizzes: [] })
      quizIndexRef.current = 0
    },
    [setDetailVideo, setResultRecord],
  )

  const getQuestionStatus = async (videoId: string, questionId: number) => {
    try {
      const response = await apis.getQuestionStatus(videoId, questionId)

      if (response.message === EResultStatus.answered) {
        setResultPreviousRecord(response.record)
      } else {
        setResultPreviousRecord(undefined)
      }
    } catch (error) {
      Logger.error('getQuestionStatus', error)
    }
  }

  useEffect(() => {
    if (userInfo?.email && videoId && questions?.[activeQuestionIndex!]?.id) {
      questions[activeQuestionIndex!].id
      getQuestionStatus(videoId, questions[activeQuestionIndex!].id)
    }
  }, [videoId, activeQuestionIndex, userInfo?.email])

  React.useEffect(() => {
    resetWatchingSession()
  }, [resetWatchingSession])

  const { visible: reviewPointVisible, showModal: showReviewPointModal, closeModal: closeReviewPointModal } = useModal()

  const {
    visible: thanksUserCodeVisible,
    showModal: showThanksUserCodeModal,
    closeModal: closeThanksUserCodeModal,
  } = useModal()

  const { visible: quizzesVisible, showModal: showQuizzesModal, closeModal: closeQuizzesModal } = useModal()

  const { sumPoint } = useRecoilValue(sumPointSelector)

  const handleReceivePrize = () => {
    const notifier = pointMessageRef.current as any

    if (!playing) {
      notifier?.setError(t('video.play_video'))
      return
    }

    const currentTime = reactPlayerRef.current?.getCurrentTime()
    const { answers } = question || {}
    const isCorrectTime = answers?.find(
      (answerTime: any) =>
        currentTime >= answerTime - ACCEPTED_TOLERANCE && currentTime <= answerTime + ACCEPTED_TOLERANCE,
    )
    if (isCorrectTime! >= 0) {
      if (checkedAnswers[isCorrectTime!]) {
        // this answer is counted --> ignore
        notifier?.setError(t('video.resulted'))
        return
      }
      checkedAnswers[isCorrectTime!] = true
    }

    const point = formatPoint(isCorrectTime! >= 0 ? pointRule.addResultNumber : pointRule.subtractResultNumber)

    // notify
    if (point > 0) notifier?.setSuccess(t('video.bonus_score', { score: `+${point} ${textPoint}` }))
    else notifier?.setError(t('video.minus_score', { score: `${point} ${textPoint}` }))

    // set state
    setResultRecord({
      ...resultRecord,
      result: [...resultRecord.result, { time: currentTime, result: isCorrectTime! >= 0, point }],
    })
  }

  const handleCloseRequestModal = () => {
    hideModal()
  }

  const handleLoginOtherAccount = () => {
    hideModal()
    setAppModal({
      mode: ModalType.signIn,
      data: {
        onCancel: async (userData?: UserType) => {
          if (userData?.email && userData?.email !== DEMO_ACCOUNT.email) {
            setAppModal({ mode: ModalType.close })
            showReviewPointModal()
          }
        },
      },
    })
  }

  const handleCheckShowReviewPoint = () => {
    showReviewPointModal()
  }

  const handleFinish = () => {
    setPlaying(false)
    checkedAnswers = {}
    removeVideoInfoLocalStorage(videoId)
    reactPlayerRef.current.seekTo(0)
    handleCheckShowReviewPoint()
  }

  // eslint-disable-next-line camelcase
  const handleSubmit = async () => {
    closeReviewPointModal()
    showThanksUserCodeModal()
    // reset
    handleRefreshProfile()
    resetWatchingSession(true)
  }

  const renderWarningMessage = () => (
    <>
      {resultPreviousRecord && (
        <div>
          <div className='flex justify-center m-auto w-full text-center mt-4 mb-2 text-[#E13B19]'>
            <Trans i18nKey='video.scored' />
            <span
              className='ml-3 flex justify-center content-center font-semibold text-[#1C62B9] items-center cursor-pointer underline'
              onClick={() => resetWatchingSession(true)}
            >
              <Trans i18nKey='video.change_question' />
            </span>
          </div>
          <div>
            {t('video.previousResult', {
              concentrationLevel: resultPreviousRecord.concentration_level * 100,
              score: formatPoint(resultPreviousRecord.point),
            })}
          </div>
        </div>
      )}
    </>
  )

  const getMessage = () => {
    if (quizzes && quizzes.length > 0) {
      return is_sponsor ? 'video.score_message_sponsor' : 'video.score_message'
    }

    return is_sponsor ? 'video.only_score_message_sponsor' : 'video.only_score_message'
  }

  const renderNoteVideo = () => {
    if (exchangePoints) {
      return (
        <div>
          <div className='videoNoteReward'>
            <div className='md:block md:text-left flex items-center'>
              <Trans
                i18nKey={getMessage()}
                values={{
                  scoreCorrect: formatPoint(pointRule.addResultNumber),
                  scoreIncorrect: formatPoint(pointRule.addQuizNumber),
                }}
                components={{ highlight: <span className='highlights' /> }}
              />
            </div>
            <div className='text-[#E13B19] font-medium'>
              <Trans
                i18nKey='video.concentrationWarning'
                values={{
                  concentrationLevel,
                }}
              />
            </div>
          </div>
        </div>
      )
    }

    return <></>
  }

  const renderQuestionMessage = () => {
    let newQuestion = question?.word ?? ''

    if (!isEmpty(keywords) && newQuestion && typeof activeQuestionIndex !== 'undefined') {
      const currentKeyword = keywords[activeQuestionIndex]

      const currentKeywordSplit = currentKeyword.split('-')

      currentKeywordSplit.forEach((item) => {
        const regex = new RegExp(`"${item.trim()}"`, 'gi')
        newQuestion = newQuestion.replace(regex, `<span class="word-bold test">"${item}"</span>`)
      })
    }

    return <div className='answerKeyword' dangerouslySetInnerHTML={{ __html: newQuestion }} />
  }

  const _handleShowTrialPeriodDemo = () => {
    setTimeout(() => {
      setPlaying(false)
    }, 1)

    showModal({
      content: <TrialPeriodDemoAccountExpired />,
    })
  }

  const handlePlayClick = () => {
    if (userInfo?.email) {
      if (!playing && isCantPlay) {
        if (userInfo?.pending_plan_upgrade) {
          showPendingUpgradeModal()
        } else {
          showEndTimeOutModal()
        }
      } else if (!isAvailableUpdate && isDemoAccount) {
        _handleShowTrialPeriodDemo()
      } else {
        setPlaying((prev) => !prev)
      }
    } else {
      setAppModal({ mode: ModalType.signIn })
    }
  }

  const getQuizIndex = () => {
    return quizIndexRef?.current
  }

  const handleSaveProcessVideo = (state: OnProgressProps) => {
    const { played } = state

    if (played) {
      setVideoInfoLocalStorage(videoId, {
        time: played.toString(),
        activeQuestionIndex,
        resultRecord,
        quizIndex: getQuizIndex(),
      })
      // localStorage.setItem(`played_${videoId}`, JSON.stringify({ time: played.toString() }))
    }
  }

  const updateFreeTime = async () => {
    try {
      const updateResponse = await apis.updateLimitTime({
        minutes_limit: currentLimitTimeRef.current - 1,
      })

      if (updateResponse.message.toLowerCase() === 'ok') {
        if (updateResponse.minutes_limit >= -1) {
          setUserInfo((values) => ({ ...values, minutes_limit: updateResponse.minutes_limit }))
          currentLimitTimeRef.current -= 1
          totalTimeWatchVideo.current -= 60
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const updateFreeTimeDemoAccount = async () => {
    try {
      if (totalTimeWatchVideo.current > 60) {
        totalTimeWatchVideo.current -= 60
        updateMinutesUsed(1)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const updateAvailableTime = async () => {
    try {
      const updateResponse = await apis.updateAvailableTime({
        minutes: 1,
      })

      if (updateResponse.message?.toLowerCase() === 'ok') {
        if (updateResponse.available_minutes >= -1) {
          setUserInfo((values) => ({
            ...values,
            available_minutes: updateResponse?.available_minutes,
            points: updateResponse?.points,
          }))
          currenAvailableTimeRef.current -= 1
          totalTimeWatchVideo.current -= 60
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleUpdateTime = () => {
    const isFreeTime = currentLimitTimeRef.current > 0

    if (isFreeTime) {
      updateFreeTime()
    } else {
      updateAvailableTime()
    }
  }

  const updateUsedTime = useCallback(
    throttle((videoState: OnProgressProps) => {
      if (isVideoFree && videoState.playedSeconds !== 0) {
        if (totalTimeWatchVideo.current >= 60) {
          handleUpdateTime()
        }
      }
    }, 25000),
    [],
  )

  const checkLimitTime = (videoState: OnProgressProps) => {
    if (istTimeOutVideo && videoState.playedSeconds !== 0) {
      setTimeout(() => {
        setPlaying(false)
      }, 1)

      showTimeOutModal({
        callBackOnClose() {
          hideModal()
          setPlaying(false)
        },
      })

      return
    }

    if (!isAvailableUpdate && isDemoAccount) {
      _handleShowTrialPeriodDemo()
    }
  }

  const countTimeWatchVideo = (videoState: OnProgressProps) => {
    if (stateTimeAgoRef?.current?.playedSeconds) {
      totalTimeWatchVideo.current += videoState.playedSeconds - stateTimeAgoRef.current.playedSeconds
    }

    stateTimeAgoRef.current = videoState
  }

  const handleVideoProgress = useCallback(
    (state: any) => {
      handleSaveProcessVideo(state)
      countTimeWatchVideo(state)

      if (userInfo?.customer?.minutes_limit !== 0) {
        if (!isDemoAccount) {
          updateUsedTime(state)
        } else {
          updateFreeTimeDemoAccount()
        }
        checkLimitTime(state)
      }

      if (!quizzes?.[quizIndexRef.current]) return
      const { playedSeconds } = state

      if (quizzes?.[quizIndexRef.current].show_at! <= playedSeconds) {
        setTimeout(() => {
          setPlaying(false)
        }, 400)
        showQuizzesModal()
      }
    },
    [userInfo, quizzes],
  )

  useEffect(() => {
    if (currentLimitTimeRef.current) {
      currentLimitTimeRef.current = userInfo?.minutes_limit ?? 0
    }
    if (currenAvailableTimeRef.current) {
      currenAvailableTimeRef.current = userInfo?.available_minutes ?? 0
    }
  }, [userInfo?.minutes_limit, userInfo?.available_minutes])

  const handleClickContinue = () => {
    const response = getVideoInfoLocalStorage(videoId)

    if (response?.resultRecord) {
      setResultRecord(response?.resultRecord)
      quizIndexRef.current = response?.quizIndex ?? 0
      setDetailVideo((result) => ({ ...result, activeQuestionIndex: response.activeQuestionIndex } as VideoType))
    }

    reactPlayerRef?.current?.seekTo(response?.time)
    reactLayerPlayerRef?.current?.click()
    setShowModalContinue(false)
  }

  const handleClickStart = () => {
    removeVideoInfoLocalStorage(videoId)
    reactLayerPlayerRef?.current?.click()
    setShowModalContinue(false)
  }

  // handle show remaining 5 minutes popup
  useEffect(() => {
    if (userInfo?.customer?.minutes_limit !== 0) {
      if (playing && userInfo.minutes_limit && userInfo.minutes_limit === 5 && userInfo.available_minutes === 0) {
        show5MinutesModal()
        setPlaying(false)
      } else if (
        playing &&
        isNumber(userInfo.minutes_limit) &&
        isNumber(userInfo.available_minutes) &&
        userInfo.minutes_limit <= 0 &&
        userInfo.available_minutes <= 0
      ) {
        showEndTimeOutModal()
        setPlaying(false)
        // reactPlayerRef.current?.
      }
    }
  }, [userInfo.minutes_limit, userInfo.available_minutes, playing])

  useEffect(() => {
    const checkTimeWatchedVideo = () => {
      const data = getVideoInfoLocalStorage(videoId)

      if (data) {
        if (istTimeOutVideo) {
          setShowModalContinue(false)
        } else {
          setShowModalContinue(true)
        }
      }
    }
    setTimeout(() => {
      checkTimeWatchedVideo()
    }, 500)
  }, [])

  const renderDeepViewBtn = () => {
    return (
      <div className='boxDeepview'>
        <span className='content md:flex'>{renderQuestionMessage()}</span>
        <div className='block md:hidden'>{renderWarningMessage()}</div>
        <ReceivePrizeBtn onClick={handleReceivePrize} ref={pointMessageRef} className='receivePrizeBtn' />
      </div>
    )
  }

  const handlePlayerReady = (player: any) => {
    player.getInternalPlayer().setOption('captions', 'translate')
    player.getInternalPlayer().setOption('cc_lang_pref', codeLanguage)
  }

  return (
    <Row className='videoWrapper w-full'>
      <div className='videoContainer'>
        <div className='playerWrapper'>
          <ReactPlayer
            url={video}
            playing={playing}
            onEnded={handleFinish}
            width='100%'
            height='100%'
            className='reactPlayer'
            // controls
            ref={reactPlayerRef}
            config={{
              youtube: {
                playerVars: {
                  controls: 0,
                  showinfo: 0,
                  modestbranding: 1,
                  captions: 'translate',
                  // rel: 0,
                  cc_load_policy: 1,
                  playsinline: 1,
                  cc_lang_pref: codeLanguage,
                  tlang: codeLanguage,
                  hl: codeLanguage,
                },
              },
              file: { attributes: { playsInline: true } },
            }}
            playsinline
            progressInterval={300}
            onReady={handlePlayerReady}
            onProgress={handleVideoProgress}
          />
          {/* trick: due to cannot hide control player of youtube, then make an overlay above video to handle play/pause */}
          <div ref={reactLayerPlayerRef} className='playerButtonOverlay' onClick={handlePlayClick}>
            {!mobileAndTabletCheck && <img className='playPauseIcon' src={playing ? pauseImg : playImg} alt='' />}
          </div>
        </div>

        <div className='hidden md:block'>
          <div className='flex infoVideoMobile'>
            <img className='logoChannelMobile' src={customer.avatar ?? imagesAssets.avatar} alt='name' />
            <div>
              <div className='videoName'>{name}</div>
              <div className='channelName'>{customer.display_name ?? 'No Name'}</div>
            </div>
          </div>

          <div className='flex boxDeepViewMobile'>{renderDeepViewBtn()}</div>

          <div className='noteMobile'>{renderNoteVideo()}</div>

          <div className='flex my-5 justify-between px-3'>
            <span className='hidden md:block'>{renderWarningMessage()}</span>
            <ShareBtn />
          </div>

          <div className='promotion boxPointMobile'>
            <BoxUsedPoint exchangePoints={exchangePoints} />
          </div>
        </div>

        <div className='promotion md:hidden'>
          <BoxUsedPoint exchangePoints={exchangePoints} />
          {renderDeepViewBtn()}
        </div>
      </div>
      {!mobileAndTabletCheck && (
        <div className='videoNameWrapper'>
          <div className='videoName'>
            {name}
            {renderNoteVideo()}
          </div>
          <ShareBtn />
        </div>
      )}

      <div className='infoChannel flex md:hidden'>
        <img className='channelLogo' src={customer.avatar ?? imagesAssets.avatar} alt='name' />
        <div className='channelName'>{customer.display_name ?? 'No Name'}</div>
      </div>

      <ReviewPointModal
        open={reviewPointVisible}
        onReWatch={() => {
          closeReviewPointModal()
          resetWatchingSession(true)
        }}
        onSubmit={handleSubmit}
        isAnsweredQuestion={!!resultPreviousRecord}
        data={detailVideo ?? ({} as VideoType)}
      />

      <ThanksUserCodeModal open={thanksUserCodeVisible} onClose={closeThanksUserCodeModal} />
      {quizzesVisible && (
        <QuizzesModal
          open={quizzesVisible}
          quiz={quizzes?.[quizIndexRef.current]!}
          quizIndex={quizIndexRef.current}
          onClose={() => {
            closeQuizzesModal()
            quizIndexRef.current++
            setPlaying(true)
          }}
        />
      )}
      <Modal
        open={showModalContinue}
        okText={t('video.okContinue')}
        cancelText={t('video.cancelContinue')}
        onCancel={handleClickStart}
        onOk={handleClickContinue}
        className='modalBase'
        title={t('video.continueVideoQuestion')}
      >
        {t('video.continueVideoNote')}
      </Modal>
    </Row>
  )
}

export default Video
